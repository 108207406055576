import { createClient } from "../prismicio";

export default function Custom404() {
	return (
		<main
			style={{ padding: "180px 20px 100px", textAlign: "center" }}
			data-contain="true"
		>
			<h1>Error 404</h1>
			<h2>Sorry the page you are looking for is not found. </h2>
		</main>
	);
}

export async function getStaticProps({ previewData }) {
	const client = createClient({ previewData });

	const nav = await client.getSingle("nav");
	const footer = await client.getSingle("footer");

	return {
		props: {
			nav,
			footer,
		},
	};
}
